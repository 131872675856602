import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp'
import { dealProductsPretreatInfo } from '@/public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { ITEM_CONFIGS_V3 } from './constants'

export const getPageAbt = async () => {
  // 凑单 相关
  const newKeys = [
    'addnewhotJson', 
    'Cartshowcoupon', 
    'CartaddCouponBottomStyle', 
    'orderCouponNewUser',
    'CartaddPromotionBottomStyle',
    'Cartshowcoupon',
    'cartautopoints',
    'CartaddFeeds',
  ]
  // 推荐/商卡 相关
  const cardNewKeys = [
    'listgoodsCard',
    'listflashSale',
    'AllListStarReview',
    'listquickship',
    'listquickshipKey',
    'RecommendExpandPop',
  ]
  // eslint-disable-next-line
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys: [...newKeys, ...cardNewKeys].join(',') })
  return abtInfo
}

export const getCardConfig = async () => {
  return schttp({
    url: '/api/productAtom/cardConfig/get',
    method: 'get',
    params: {
      newProductCard: true, // 是否新商卡 1  0
      column: 2, // 商卡一图几行  1 2 3
    }
  })
}

export const getLanguage = async () => {
  return schttp({
    url: '/api/common/language/get',
    params: { page: 'filter' }
  })
}

// TODO
export async function getInterfaceList(params, { language, config = {}, scene = 'one' } = {}) {
  let res = {}, isFaultTolerant = false
  try {
    res = await getLists(params)
  } catch(e) {
    res = await getInsuranceList({
      ...params,
      cate_id: params.filter_cate_id,
      good_ids: params.goodsIds,
      rec_type: 1, // 推荐类型；1：商品  4：filter类目
      pagename: 'page_for_addon',
    })
    isFaultTolerant = true
  }

  let { info, code, msg } = res || {}
  const sceneInfo = {
    one: {
      pageKey: 'page_cart',
      subPageKey: 'other_recommend_1pic',
      sceneKey: 'ONE_IN_A_ROW'
    },
    two: {
      pageKey: 'page_cart',
      subPageKey: 'other_recommend_2pic',
      sceneKey: 'TWO_IN_A_ROW'
    },
    three: {
      pageKey: 'page_cart',
      subPageKey: 'other_recommend_3pic',
      sceneKey: 'THREE_IN_A_ROW'
    },
  }

  const goodsInfo = new GoodsItemInfo({ itemLanguage: language })
  // 混入原子服务数据
  try {
    if (info?.products.length) {
      await goodsInfo.getProductInfo({
        goods: info?.products,
        requestFields: {
          locateLabels: true,
          prices: true,
          cccFeedback: true,
          cccTspBadges: true,
          baseInfo: true,
          seriesAndBrand: config.showSeriesBrand,
          mallInfo: params.mall_code ? false : true,
          sellingPoints: config.showSellingPoint,
          promotion: true,
          sheinClubPromotionInfo: true, // 付费会员抵扣
          quickShip: config.showQuickShip,
          promoLabel: config.showPromoLabel,
        },
        itemConfig: config,
        ...sceneInfo[scene || 'one']
      })
    }
  } catch(e) {
    console.log('原子服务报错', e)
  }

  return {
    products: info?.products || [],
    total: info?.total || 0,
    isFaultTolerant,
    code: code,
    msg: msg,
  }
}

export const getItemConfigV3 = (config) => {
  const res = {
    ...ITEM_CONFIGS_V3,
    ...config.cardConfig?.cardConfig,

    // 凑单场景特殊参数
    disableMainimgJump: true,
    disableApartFromTheDetailsJump: true,
    showDeliveryWords: false, // 凑单弹窗不接入趋势
    showEstimatedPriceOnSale: false, // 凑单弹窗不接入到手价
    style: {
      // 免邮凑单弹窗，优惠券凑单弹窗，加购按钮配置成 ADD 文字
      addBagBtnStyle: ['freeShipping', 'coupon'].includes(config.type) && ['checkout_shipping_add', 'checkout_shipping_coupon_add'].includes(config.activity_from) ?  'AddText' : 'default'
    },
  }

  return res
}

const getAtomParams = (params, config) => {
  const {
    listgoodsCard,
  } = config || {}

  const atomFields = {
    ...config.cardConfig?.fields,
    estimatedPrice: false, // 凑单弹窗不接入到手价
  }

  // 合规站点不展示销量标签
  if (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.shein_suggested_sale_price?.[gbCommonInfo.SiteUID]) {
    Reflect.deleteProperty(atomFields, 'salesLabel')
  }

  return {
    atomFields,
    atomScene: {
      pageKey: 'page_add_cart',
      sceneKey: 'TWO_IN_A_ROW',
    },
    atomCccParams: {
      cateId: '111',
      displayWindowAbtBranch: listgoodsCard?.param?.listGoods || '',
    },
    caller: ''
  }
}

export const getListsV3 = (params, config = {}) => {
  const {
    resultType = '',
    limit,
    req_num,
    pageEnable,
    scene_id,
    pageNum,
    jsonRuleId,
    location,
    cateIds,
    goodsIds,
    include_tsp_id,
    exclude_tsp_id,
    goods_price,
    add_on_type,
    mall_code,
    sort,
    quickship_prior,
    stock_enough,
    adp,
    shop_id,
    ...other
  } = params
  const {
    isAtom,
    listflashSale,
    AllListStarReview,
    listquickship,
    listquickshipKey,
  } = config
  let atomParams = {}
  if (isAtom) {
    atomParams = getAtomParams(params, config)
  }
  const contextParams = {
    cate_ids: cateIds,
    goods_ids: goodsIds,
    goods_price,
    add_on_type,
    mall_code,
    sort,
    quickship_prior,
    stock_enough,
    shop_id,
    ...other,
  }
  // TR-27530支持外围传入指定商品id,只在第一页进行adp传参
  if (adp && adp.length) {
    if (pageNum > 1) {
      contextParams.adp = ''
    } else {
      contextParams.adp = adp instanceof Array ? adp.slice(0, 10).join(',') : adp
    }
  }
  // 不能传空字符串
  if (include_tsp_id) contextParams.include_tsp_id = include_tsp_id
  if (exclude_tsp_id) contextParams.exclude_tsp_id = exclude_tsp_id
  const data = {
    resultType,
    limit,
    req_num,
    pageEnable,
    scene_id,
    pageNum,
    jsonRuleId: JSON.stringify(jsonRuleId),
    location,
    newPreHandle: true,
    abtInfoV3: {
      newProductCard: config.newProductCard,
      listflashSale: listflashSale?.param?.listflashSale == 'flashSale', // 闪购腰带
      starReview: AllListStarReview?.param?.liststar,
      listQuickShip: listquickship?.param?.listquickship,
      listquickKey: listquickshipKey?.param?.listquickKey,
    },
    contextParams,
    ...atomParams,
  }
  return schttp({
    url: '/api/recommend/facadeAtom/get',
    method: 'POST',
    data,
  })
}

export const getIntegrityPromotionInfo = async (products) => {
  const { data: promotionInfoFromServer = {} } = await schttp({
    method: 'POST',
    url: '/api/productInfo/postIntegrityPromotionInfo/get',
    data: {
      // asyncPromotionIds: '',
      // fetchedPromotionIds: '',
      products,
    }
  })
  return promotionInfoFromServer
}

export const getInterfaceListV3 = async (params, config = {}) => {
  const res = await getListsV3(params, { ...config, isAtom: true })
  const { info, code, msg } = res || {}
  let products = info?.products || []

  let promotionInfoFromServer
  try {
    promotionInfoFromServer = await getIntegrityPromotionInfo(products)
  } catch (error) {
    console.error('getIntegrityPromotionInfo', error)
  }

  if (promotionInfoFromServer) {
    products = dealProductsPretreatInfo({
      products,
      itemConfig: getItemConfigV3(config),
      promotionInfoFromServer,
      // language: {},
    })
  }

  return {
    products,
    total: info?.total || 0,
    code,
    msg,
    isFaultTolerant: false,
  }
}
